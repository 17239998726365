import React from 'react';
import { WidthContainer } from '../..';
import './SeafoodHealthBenefits.scss';

interface ISeafoodHealthBenefitsProps {
    heading: string;
    text: string;
    boxOneImage: {
        altText: string;
        sourceUrl: string;
    }
    boxOneHeading: string;
    boxOneText: string;

    boxTwoImage: {
        altText: string;
        sourceUrl: string;
    }
    boxTwoHeading: string;
    boxTwoText: string;


    boxThreeImage: {
        altText: string;
        sourceUrl: string;
    }
    boxThreeHeading: string;
    boxThreeText: string;

    boxFourImage: {
        altText: string;
        sourceUrl: string;
    }
    boxFourHeading: string;
    boxFourText: string;

    boxFiveImage: {
        altText: string;
        sourceUrl: string;
    }
    boxFiveHeading: string;
    boxFiveText: string;

    boxSixImage: {
        altText: string;
        sourceUrl: string;
    }
    boxSixHeading: string;
    boxSixText: string;

    boxSevenImage: {
        altText: string;
        sourceUrl: string;
    }
    boxSevenHeading: string;
    boxSevenText: string;

    boxEightImage: {
        altText: string;
        sourceUrl: string;
    }
    boxEightHeading: string;
    boxEightText: string;

    boxNineImage: {
        altText: string;
        sourceUrl: string;
    }
    boxNineHeading: string;
    boxNineText: string;

    boxTenImage: {
        altText: string;
        sourceUrl: string;
    }
    boxTenHeading: string;
    boxTenText: string;
}

export const SeafoodHealthBenefits: React.FC<ISeafoodHealthBenefitsProps> = (props) => {
    return (
        <div className="SeafoodHealthBenefits__Outer">
            <WidthContainer>
                <div className="SeafoodHealthBenefits">
                    <img className="SeafoodHealthBenefits__LeftSide" src='/images/shb-fish.svg' alt="Decorative fish illustration" />
                    <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                    <div className="SeafoodHealthBenefits__Columns">
                        <div>
                            <div>
                            <img src={props.boxOneImage.sourceUrl} alt={props.boxOneImage.altText} />
                            <h5>{props.boxOneHeading}</h5>
                            {
                                props.boxOneText && (
                                    <p dangerouslySetInnerHTML={{ __html: props.boxOneText }}></p>
                                )
                            }
                            </div>
                        </div>

                        <div>
                            <div>
                            <img src={props.boxTwoImage.sourceUrl} alt={props.boxTwoImage.altText} />
                            <h5>{props.boxTwoHeading}</h5>
                            {
                                props.boxTwoText && (
                                    <p dangerouslySetInnerHTML={{ __html: props.boxTwoText }}></p>
                                )
                            }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxThreeImage.sourceUrl} alt={props.boxThreeImage.altText} />
                                <h5>{props.boxThreeHeading}</h5>
                                {
                                    props.boxThreeText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxThreeText }}></p>
                                    )
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxFourImage.sourceUrl} alt={props.boxFourImage.altText} />
                                <h5>{props.boxFourHeading}</h5>
                                {
                                    props.boxFourText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxFourText }}></p>
                                    )
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxFiveImage.sourceUrl} alt={props.boxFiveImage.altText} />
                                <h5>{props.boxFiveHeading}</h5>
                                {
                                    props.boxFiveText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxFiveText }}></p>
                                    )
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxSixImage.sourceUrl} alt={props.boxSixImage.altText} />
                                <h5>{props.boxSixHeading}</h5>
                                {
                                    props.boxSixText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxSixText }}></p>
                                    )
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxSevenImage.sourceUrl} alt={props.boxSevenImage.altText} />
                                <h5>{props.boxSevenHeading}</h5>
                                {
                                    props.boxSevenText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxSevenText }}></p>
                                    )
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxEightImage.sourceUrl} alt={props.boxEightImage.altText} />
                                <h5>{props.boxEightHeading}</h5>
                                {
                                    props.boxEightText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxEightText }}></p>
                                    )
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxNineImage.sourceUrl} alt={props.boxNineImage.altText} />
                                <h5>{props.boxNineHeading}</h5>
                                {
                                    props.boxNineText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxNineText }}></p>
                                    )
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <img src={props.boxTenImage.sourceUrl} alt={props.boxTenImage.altText} />
                                <h5>{props.boxTenHeading}</h5>
                                {
                                    props.boxTenText && (
                                        <p dangerouslySetInnerHTML={{ __html: props.boxTenText }}></p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <img className="SeafoodHealthBenefits__RightSide" src='/images/turtle-fish.svg' alt="Decorative fish illustration" />
                </div>
            </WidthContainer>
        </div>
    )
}