import { string } from 'prop-types';
import React from 'react';
import { Button } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './OnTheGoSticky.scss';

interface IOnTheGoStickyProps {
    blockOneImage: {
        altText: string;
        sourceUrl: string;
    };
    blockOneContent: string;
    blockThreeImage: {
        altText: string;
        sourceUrl: string;
    };
    blockThreeContent: string;
    blockTwoImage: {
        altText: string;
        sourceUrl: string;
    };
    blockTwoContent: string;
    heading: string;
}

export const OnTheGoSticky: React.FC<IOnTheGoStickyProps> = (props) => {
    return (
        <div className="OnTheGoSticky" id="didYouKnow">
            <div className="OnTheGoSticky__LeftLane">
                <div className="OnTheGoSticky__StickyContainer">
                    <p>
                        <span dangerouslySetInnerHTML={{ __html: props.heading }}></span>
                        <img className="bubbles" src="/images/sticky-bubbles.svg" alt="Decorative bubbles" />
                    </p>
                    <img src="/images/sub.svg" alt="Whale Ilustration" />
                </div>
            </div>
            <div className="OnTheGoSticky__RightLane">
                <FadeIn>
                <div className="OnTheGoSticky__Block">
                    <img src={props.blockOneImage.sourceUrl} alt={props.blockOneImage.sourceUrl} />
                    <div dangerouslySetInnerHTML={{ __html: props.blockOneContent }} />
                </div>
                </FadeIn>
                <FadeIn>
                <div className="OnTheGoSticky__Block">
                    <img src={props.blockTwoImage.sourceUrl} alt={props.blockTwoImage.sourceUrl} />
                    <div dangerouslySetInnerHTML={{ __html: props.blockTwoContent }} />
                </div>
                </FadeIn>
                <FadeIn>
                <div className="OnTheGoSticky__Block">
                    <img src={props.blockThreeImage.sourceUrl} alt={props.blockThreeImage.sourceUrl} />
                    <div dangerouslySetInnerHTML={{ __html: props.blockThreeContent }} />
                </div>
                </FadeIn>
            </div>
        </div>
    )
}