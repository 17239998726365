import React from 'react';
import { graphql } from 'gatsby';
import { FooterArt, SmallKeyArt, Layout, PageContent, Seo, WidthContainer, WaveToDinnerTable, LongTerm } from '../components';
import { PantryItemsHeader } from '../components/WhatCanIDo/PantryItemsHeader/PantryItemsHeader';
import { Tinned } from '../components/WhatCanIDo/Tinned/Tinned';
import { Freezer } from '../components/WhatCanIDo/Freezer/Freezer';
import { Refrigerator } from '../components/WhatCanIDo/Refrigerator/Refrigerator';
import { Pantry } from '../components/WhatCanIDo/Pantry/Pantry';
import { OnTheGo } from '../components/WhatCanIDo/OnTheGo/OnTheGo';
import { TopPicks } from '../components/WhatCanIDo/TopPicks/TopPicks';
import { SeafoodHealthBenefits } from '../components/WhatCanIDo/SeafoodHealthBenefits/SeafoodHealthBenefits';
import { OnTheGoSticky } from '../components/WhatCanIDo/OnTheGoSticky/OnTheGoSticky';

export default ({ data }) => {
    const fields = data.wpPage.whatCanIDoFields;

    return (
        <Layout>
            <Seo title='Wave to Table' description="test" />
            <SmallKeyArt />
            <PageContent>
                <WidthContainer>
                    <WaveToDinnerTable heading={fields.waveToDinnerTableHeading} text={fields.waveToDinnerTableText} />
                </WidthContainer>
                <SeafoodHealthBenefits 
                    heading={fields.shbHeading}
                    text={fields.shbText}
                    boxOneImage={fields.shbBoxoneimage}
                    boxOneHeading={fields.shbBoxoneheading}
                    boxOneText={fields.shbBoxonetext}
                    boxTwoImage={fields.shbBoxtwoimage}
                    boxTwoHeading={fields.shbBoxtwoheading}
                    boxTwoText={fields.shbBoxtwotext}
                    boxThreeImage={fields.shbBoxthreeimage}
                    boxThreeHeading={fields.shbBoxthreeheading}
                    boxThreeText={fields.shbBoxthreetext}
                    boxFourImage={fields.shbBoxfourimage}
                    boxFourHeading={fields.shbBoxfourheading}
                    boxFourText={fields.shbBoxfourtext}
                    boxFiveImage={fields.shbBoxfiveimage}
                    boxFiveHeading={fields.shbBoxfiveheading}
                    boxFiveText={fields.shbBoxfivetext}
                    boxSixImage={fields.shbBoxsiximage}
                    boxSixHeading={fields.shbBoxsixheading}
                    boxSixText={fields.shbBoxsixtext}
                    boxSevenImage={fields.shbBoxsevenimage}
                    boxSevenHeading={fields.shbBoxsevenheading}
                    boxSevenText={fields.shbBoxseventext}
                    boxEightImage={fields.shbBoxeightimage}
                    boxEightHeading={fields.shbBoxeightheading}
                    boxEightText={fields.shbBoxeighttext}
                    boxNineImage={fields.shbBoxnineimage}
                    boxNineHeading={fields.shbBoxnineheading}
                    boxNineText={fields.shbBoxninetext}
                    boxTenImage={fields.shbBoxtenimage}
                    boxTenHeading={fields.shbBoxtenheading}
                    boxTenText={fields.shbBoxtentext}
                />
                <WidthContainer>
                    <LongTerm leftHeading={fields.longTermLeftHeading} rightHeading={fields.longTermRightHeading} rightText={fields.longTermRightText} />
                </WidthContainer>   
                    <PantryItemsHeader heading={fields.pantryItemsMainHeading} text={fields.pantryItemsMainText} />      
                    <Pantry heading={fields.pantryHeading} text={fields.pantryText} items={fields.pantryItems} />
                <WidthContainer>
                    <Refrigerator heading={fields.refrigeratorHeading} text={fields.refrigeratorText} items={fields.refrigeratorItems} />
                    <Freezer heading={fields.freezerHeading} text={fields.freezerText} items={fields.freezerItems} /> 
                    {/* <Tinned heading={fields.tinnedHeading} text={fields.tinnedText} /> */}
                    {/* <OnTheGo heading={fields.onTheGoHeading} text={fields.onTheGoText} /> */}
                    {/* <TopPicks heading={fields.topPicksHeading} items={fields.topPicks} /> */}
                    <OnTheGoSticky 
                        heading={fields.otgHeading}  
                        blockOneImage={fields.otgBlockoneimage}
                        blockOneContent={fields.otgBlockonecontent}
                        blockTwoImage={fields.otgBlocktwoimage}
                        blockTwoContent={fields.otgBlocktwocontent}
                        blockThreeImage={fields.otgBlockthreeimage}
                        blockThreeContent={fields.otgBlockthreecontent}
                    />
                </WidthContainer>
            </PageContent>
            <FooterArt />
        </Layout>
    );
};

export const query = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            slug
            id
            whatCanIDoFields {
                otgHeading
                otgBlockoneimage {
                    altText
                    sourceUrl
                }
                otgBlockonecontent
                otgBlocktwoimage {
                    altText
                    sourceUrl
                }
                otgBlocktwocontent
                otgBlockthreeimage {
                    altText
                    sourceUrl
                }
                otgBlockthreecontent
                shbHeading
                shbText
                shbBoxoneimage {
                    altText
                    sourceUrl
                }
                shbBoxoneheading
                shbBoxonetext
                shbBoxtwoimage {
                    altText
                    sourceUrl
                }
                shbBoxtwoheading
                shbBoxtwotext
                shbBoxthreeimage {
                    altText
                    sourceUrl
                }
                shbBoxthreeheading
                shbBoxthreetext
                shbBoxfourimage {
                    altText
                    sourceUrl
                }
                shbBoxfourheading
                shbBoxfourtext
                shbBoxfiveimage {
                    altText
                    sourceUrl
                }
                shbBoxfiveheading
                shbBoxfivetext
                shbBoxsiximage {
                    altText
                    sourceUrl
                }
                shbBoxsixheading
                shbBoxsixtext
                shbBoxsevenimage {
                    altText
                    sourceUrl
                }
                shbBoxsevenheading
                shbBoxseventext
                shbBoxeightimage {
                    altText
                    sourceUrl
                }
                shbBoxeightheading
                shbBoxeighttext
                shbBoxnineimage {
                    altText
                    sourceUrl
                }
                shbBoxnineheading
                shbBoxninetext
                shbBoxtenimage {
                    altText
                    sourceUrl
                }
                shbBoxtenheading
                shbBoxtentext
                freezerHeading
                freezerText
                longTermLeftHeading
                longTermRightHeading
                longTermRightText
                pantryHeading
                pantryItemsMainHeading
                pantryItemsMainText
                pantryText
                refrigeratorHeading
                refrigeratorText
                waveToDinnerTableHeading
                waveToDinnerTableText
                freezerItems {
                    title
                    image {
                        altText
                        sourceUrl
                    }
                    link {
                        target
                        title
                        url
                    }
                }
                pantryItems {
                    title
                    image {
                        altText
                        sourceUrl
                    }
                    link {
                        target
                        title
                        url
                    }
                }
                refrigeratorItems {
                    title
                    image {
                        altText
                        sourceUrl
                    }
                    link {
                        target
                        title
                        url
                    }
                }
            }
        }
    }
`;
