import React from 'react';
import './Refrigerator.scss';
import { Link } from 'gatsby';
import { Button } from '../../Global/Button/Button';
import { FadeIn } from '../../Animation/FadeIn';

interface IRefrigeratorProps {
    heading: string;
    text: string;
    items: {
        title: string;
        image: {
            altText: string;
            sourceUrl: string;
        };
        link?: {
            target: string;
            title: string;
            url: string;
        }
    }[];
}

export const Refrigerator: React.FC<IRefrigeratorProps> = (props) => {
    return (
        <FadeIn>
        <div className="Refrigerator">
            <h3>{props.heading}</h3>
            <p dangerouslySetInnerHTML={{ __html: props.text}} />
            <div className="Refrigerator__Items">
                {props.items.map((item, index) => {
                    return (
                        <div className="Refrigerator__Item" key={index}>
                            <div>
                                <img src={item.image.sourceUrl} alt={item.image.altText} />
                                <p dangerouslySetInnerHTML={{ __html: item.title }} />
                            </div>
                            {
                                item.link && (
                                    <Button as="link" href={item.link.url} minWidth={'0'} target={item.link.target}>{item.link.title}</Button>
                                )
                            }
                        </div>
                    );
                })}
            </div>
        </div>
        </FadeIn>
    )
}