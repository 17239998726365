import React from 'react';
import './Pantry.scss';
import { Link } from 'gatsby';
import { Button } from '../../Global/Button/Button';
import { FadeIn } from '../../Animation/FadeIn';
import { WidthContainer } from '../..';

interface IPantryProps {
    heading: string;
    text: string;
    items: {
        title: string;
        image: {
            altText: string;
            sourceUrl: string;
        };
        link: {
            target: string;
            title: string;
            url: string;
        }
    }[];
}

export const Pantry: React.FC<IPantryProps> = (props) => {
    return (
        <div className="Pantry__Outer">
            <WidthContainer>
            <FadeIn>
            <div className="Pantry">
                <img src="/images/farm-jellyfish.svg" alt="Decorative jellyfish illustration" />
                <h3>{props.heading}</h3>
                <p>{props.text}</p>
                <div className="Pantry__Items">
                    {props.items.map((item, index) => {
                        return (
                            <div className="Pantry__Item" key={index}>
                                <div>
                                    <img src={item.image.sourceUrl} alt={item.image.altText} />
                                    <p>{item.title}</p>
                                </div>
                                <Button as="link" href={item.link.url} minWidth={'0'} target={item.link.target}>{item.link.title}</Button>
                            </div>
                        );
                    })}
                </div>
            </div>
            </FadeIn>
            </WidthContainer>
        </div>
    )
}