import React from 'react';
import { WidthContainer } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './PantryItemsHeader.scss';

interface IPantryItemsHeaderProps {
    heading: string;
    text: string;
}

export const PantryItemsHeader: React.FC<IPantryItemsHeaderProps> = ({ heading, text }) => {
    return (
        <div className="PantryItemsHeader__Outer">
            <WidthContainer>
        <FadeIn>
        <div className="PantryItemsHeader">
            <h4 dangerouslySetInnerHTML={{ __html: heading }} />
            <p dangerouslySetInnerHTML={{ __html: text }} />
            <img src="/images/herman-fish.svg" alt="Decorative fish and jellyfish" />
        </div>
        </FadeIn>
        </WidthContainer>
        </div>
    )
}