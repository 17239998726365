import { Link } from 'gatsby';
import './Freezer.scss';
import React from 'react';
import { Button } from '../../Global/Button/Button';
import { FadeIn } from '../../Animation/FadeIn';

interface IFreezerProps {
    heading: string;
    text: string;
    items: {
        title: string;
        image: {
            altText: string;
            sourceUrl: string;
        };
        link: {
            target: string;
            title: string;
            url: string;
        }
    }[];
}

export const Freezer: React.FC<IFreezerProps> = (props) => {
    return (
        <FadeIn>
        <div className="Freezer">
            <img src="/images/fish-sides.svg" alt="Decorative fish" />
            <div className="Freezer__Left">
                <h4>{props.heading}</h4>
                <img src='/images/freezer-fish.svg' alt="Decorative fish illustration" />
                <img className="Freezer__Bubbles" src="/images/sticky-bubbles.svg" alt="Decorative bubbles" />
            </div>
            <div className="Freezer__Right">
                {props.items.map((item, index) => {
                    return (
                        <div className="Freezer__Item" key={index}>
                            <div>
                                <img src={item.image.sourceUrl} alt={item.image.altText} />
                                <p>{item.title}</p>
                            </div>
                            <Button as="link" minWidth={'0'} href={item.link.url} target={item.link.target}>{item.link.title}</Button>
                        </div>
                    );
                })}
            </div>
        </div>
        </FadeIn>
    )
}